<template>
  <div class="home_wrap">
    <Header :hide-back="true" />
    <van-notice-bar v-if="noticeTitle" class="notice-bar-box" :left-icon="noticeIcon" @click="handleToNoticeList"
      scrollable :text="noticeTitle" />
    <div class="tabs">
      <div class="left">
        <div class="tab">
          <div class="active">{{ $t('home.explore') }}</div>
        </div>
      </div>

      <div class="right" @click="handleSelectArea">
        <div>{{ areaName }}</div>
        <div><img src="@/assets/img/icon/arrow-down.svg" alt=""></div>
      </div>

    </div>

    <div class="search_block">
      <div class="search">
        <input v-model="inputValue" type="text" @focus="isSearch = true; pageType = 'search'">
        <div :class="isSearch ? 'placeholder blur' : 'placeholder'">
          <span v-if="!isSearch">搜索</span>
          <img src="@/assets/img/home/search.png" alt="" class="search_icon">
        </div>
        <div class="clear_icon" @click="handleClear">
          <img v-if="isSearch" src="@/assets/img/home/clear.png" alt="">
        </div>
      </div>
      <div @click="onSearchName" v-if="isSearch" class="btn">搜索</div>
    </div>

    <div class="user_wrap">
      <!--附近用户-->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :loading-text="$t('tip.loading')"
        :loosing-text="$t('tip.refreshing')" :pulling-text="$t('tip.refresh')" :success-text="$t('tip.refreshSuccess')">
        <van-list ref="list" class="nearlist" v-model="loading" :immediate-check="false" :finished="finished"
          :finished-text="$t('tip.noMore')" @load="onReachBottom">
          <div class="nearlist-item" v-for="(item, index) in userList" :key="index" @click="handleToPage(item._id)">
            <div class="img">
              <van-image :src="$gbUtils.formatImgUrl(item.headPhoto)" fit="cover" />
            </div>
            <img v-if="item.showPhotoList && item.showPhotoList.length > 1" class="more-img"
              src="@/assets/img/home/moreImg.png" alt="">
            <div class="info">
              <div class="nickname">{{ item.nickname }}</div>
              <div class="stage"><span></span>{{ formatTime(item.loginDate) }}</div>
            </div>
            <div class="tag">
              <div class="age" v-if="item.age">
                {{ item.age }}岁
              </div>
              <div class="height" v-if="item.height">
                {{ item.height }}cm
              </div>
              <div class="job" v-if="item.job">
                {{ item.job }}
              </div>
            </div>
            <div class="position">
              <img src="@/assets/img/position.png" alt="" />
              <div>{{ item.locationName }} </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <!--地区选择-->
    <ChooseAreaPop v-model="showArea" @close="closeAreaPop" />

  </div>
</template>

<script>
import bus from '@/utils/bus'
import Header from '../../components/Header.vue'
import { mapState, mapActions } from 'vuex'
import { queryRecentlyUser, queryUserByName } from '@/service/user'
import $gbUtils from "@/utils/gbUtils";
import ChooseAreaPop from "@/views/home/ChooseAreaPop.vue";
import { getHomeNotice } from '@/service/base'
export default {
  name: 'home',
  components: { Header, ChooseAreaPop },
  data() {
    return {
      noticeIcon: require('@/assets/img/icon/notice.svg'),
      pageType: 'normal',
      inputValue: "",
      isSearch: false,
      location: [],
      refreshing: false, // 下拉刷新状态
      loading: false, // 加载状态
      finished: false, // 禁止加载
      userList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      currLang: null,
      showArea: false,
      noticeTitle: '',
    }
  },
  computed: {
    $gbUtils() {
      return $gbUtils
    },
    isSign() {
      return this.$gbUtils.isSign()
    },
    areaName() {
      return this.$gbUtils.getAreaNameByCode(this.areaCode)
    },
    ...mapState(['lang', 'homeData', 'areaCode', 'userInfo'])
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/userDetail') {
      this.setHomeData(this.$refs.list.scroller.scrollTop);
    } else {
      this.$store.commit('SET_HOME_DATA', {})
    }
    next();
  },
  methods: {
    ...mapActions(['changeAreaCode',"changeShowLoginPop"]),
    init() {
      // 未选择性别
      if (this.isSign && this.userInfo.sex === 0) {
        this.$toast('请先完善资料')
        this.$router.push({ path: '/gender' })
        return
      }
      // 选择女性，但未上传认证视频
      if (this.isSign && this.userInfo.sex === 2 && this.userInfo.status === 10) {
        this.$toast('请先完善资料')
        this.$router.push({ path: '/uploadVideo' })
      }
      this.queryHomeNotice()
    },

    // 获取首页公告
    async queryHomeNotice() {
      const res = await getHomeNotice()
      if (res.code === 200) {
        this.noticeTitle = res.data[0].title
      }
    },
    handleToPage(userId) {
      this.$router.push({ path: "/userDetail", query: { userId: userId } });
    },
    handleClear() {
      this.resetPageState();
      this.onRefresh();
    },
    resetPageState() {
      this.inputValue = '';
      this.isSearch = false;
      this.pageType = 'normal';
      this.$store.commit('SET_HOME_DATA', {});
      this.$refs.list.scroller.scrollTop = 0;
      this.loading = false;
      this.finished = false;
    },
    onSearchName() {
      // if (!this.isSign) {
      //   this.$toast(this.$t('common.unLoginTip'))
      //   return
      // }
      if (!this.inputValue) {
        this.$toast('请输入搜索内容')
        return
      }

      this.pageNum = 1;
      this.userList = [];

      this.$store.commit('SET_HOME_DATA', {});
      this.$refs.list.scroller.scrollTop = 0;
      this.getSearchList();
    },
    async getSearchList() {
      const params = {
        'nickname': this.inputValue,
        'pageSize': this.pageSize,
        'pageNum': this.pageNum
      }
      const resp = await queryUserByName(params)
      this.loading = false
      if (resp.code !== 200) return
      const rowsData = this.formatUserData(resp.data.rows)
      this.total = resp.total
      if (this.pageNum === 1) {
        this.userList = rowsData
      } else {
        this.userList.push(...rowsData)
      }
      this.finished = this.pageNum * this.pageSize >= this.total;
    },
    async onRefresh() {
      this.pageNum = 1
      this.total = 0
      this.finished = false

      if (this.pageType === 'normal') {
        this.refreshing = true
        await this.getUserList();
        this.refreshing = false;
      } else {
        this.refreshing = true
        await this.getSearchList()
        this.refreshing = false;
      }

    },
    async getUserList() {
      const params = {
        location: this.areaCode,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      const resp = await queryRecentlyUser(params)
      this.loading = false
      if (resp.code !== 200) return
      const rowsData = this.formatUserData(resp.data.rows)
      this.total = resp.data.total;
      if (this.pageNum === 1) {
        this.userList = rowsData
      } else {
        this.userList.push(...rowsData)
      }
      if (this.pageNum * this.pageSize >= this.total) this.finished = true
    },
    formatUserData(lists) {
      ``
      let resultArray = [...lists];
      resultArray.forEach(item => {
        let imageArr = item.photos ? item.photos : item.headPhoto
        if (imageArr) {
          item.showPhotoList = imageArr.split(',')
        }
        item.locationName = this.$gbUtils.getAreaNameByCode(item.location)
      });
      return resultArray;
    },
    async onReachBottom() {
      console.log('onReachBottom ....')
      if (this.pageNum * this.pageSize < this.total) {
        this.pageNum++
      } else {
        this.finished = true;
        return
      }
      if (this.pageType === 'normal') {
        await this.getUserList()
      } else {
        await this.getSearchList()
      }
    },
    formatTime(time) {
      return this.$gbUtils.formatOnlineTime(time, this.lang)
    },
    setHomeData(scrollTop) {
      this.$store.commit('SET_HOME_DATA', {
        scrollTop: scrollTop,
        tabIndex: this.tabIndex,
        userList: this.userList,
        pageNum: this.pageNum,
        total: this.total,
        pageType: this.pageType,
        inputValue: this.inputValue,
        isSearch: this.isSearch,
      })
    },
    initPageData() {
      if (Object.keys(this.homeData).length > 0) {
        this.userList = this.homeData.userList;
        this.pageNum = this.homeData.pageNum;
        this.total = this.homeData.total;
        this.pageType = this.homeData.pageType;
        this.inputValue = this.homeData.inputValue;
        this.isSearch = this.homeData.isSearch;
        this.loading = true;
        this.$nextTick(() => {
          this.$refs.list.scroller.scrollTop = this.homeData.scrollTop;
          this.loading = false;
        })

        if (this.userList.length === 0 && this.tabIndex === 1) {
          this.onRefresh();
        }
      } else {
        this.onRefresh();
      }
    },
    handleSelectArea() {
      // if(!this.isSign) {
      //   this.changeShowLoginPop(true)
      //   return
      // }
      this.showArea = true
    },
    closeAreaPop() {
      this.showArea = false
    },
    handleToNoticeList() {
      console.log('handleToNoticeList...')
      this.$router.push({ path: '/noticeList' })
    }

  },

  async mounted() {
    this.init();
    this.currLang = this.lang
    this.initPageData();

    // 邀请链接注册
    const inviteCode = this.$route.query.inviteCode || ''
    if (inviteCode) {
      bus.$emit('openRegisterPop', inviteCode)
    }

    bus.$on('getAreaData', (key, data) => {
      if (key === 'loadResource') {
        this.changeAreaCode(data.area.code)
        // 修改地区，需要重新获取数据
        this.onRefresh()
      } else if (key === 'loadResourceForCity') {
        const code = data.city.code + ''
        this.changeAreaCode(code)
        this.onRefresh()
      }
    })


    bus.$on('reloadHomeData', () => {
      this.onRefresh()
    })
  }
}
</script>

<style lang="scss" scoped>
/deep/.van-list__finished-text {
  width: 100%;
}

.home_wrap {
  width: 100%;
  height: 100%;
  background-color: $themeBgColor;
  display: flex;
  flex-direction: column;
  padding: 64px 20px 0 20px;
  box-sizing: border-box;

  .notice-bar-box {
    color: #FD2084;
    height: 28px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #1E232D;
    font-size: 14px;
    min-width: 28px;
    padding-left: 6px;
    margin-bottom: 13px;
  }

  .search_block {
    display: flex;
    align-items: center;
    width: 100%;
    height: 38px;
    margin: 8px auto 0;

    .search {
      flex: 1;
      background-color: #000000;
      position: relative;
      height: 100%;
      border-radius: 4px;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        line-height: 38px;
        padding-left: 30px;
        background-color: transparent;
      }

      .placeholder {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #6A6A6A;
          margin-right: 5px;
        }

        .search_icon {
          width: 14px;
        }

        &.blur {
          left: 10px;
          transform: translate(0, -50%);
          -webkit-transform: translate(0, -50%);
        }
      }



      .clear_icon {
        width: 30px;
        height: 38px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    .btn {
      width: 70px;
      height: 38px;
      background: #8A73D0;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      margin-left: 10px;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 60%;
      display: flex;
      align-items: center;

      .tab {
        margin-right: 30px;

        .default {
          font-weight: 300;
          font-size: 16px;
        }

        .active {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 22px;
          color: #FD2084;

          img {
            width: 18px;
            margin-right: 4px;
          }
        }
      }

    }

    .right {
      //width: 40%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &>div {
        font-size: 14px;
        margin-right: 5px;

        &:last-child {
          margin-top: 2px;
          margin-right: 1px;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .user_wrap {
    margin-top: 16px;
    flex: 1;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 8px;
      position: relative;
    }

    /*!* Track *!*/
    &::-webkit-scrollbar-track {
      background: transparent;

    }

    /*!* Handle *!*/
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 5px;
    }

    .image_filter {
      opacity: 0.5;
    }



    .van-list {
      display: flex;

      &.nearlist {
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }


    .nearlist-item {
      background-color: #000000;
      color: $themeTextColor;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: calc(50% - 10px);
      border-radius: 20px;
      padding-bottom: 10px;
      overflow: hidden;
      position: relative;

      .more-img {
        width: 14px;
        position: absolute;
        right: 19px;
        top: 14px;
        z-index: 2;
      }

      .img {
        width: 100%;
        height: 160px;
        margin-bottom: 10px;
        overflow: hidden;
        border-radius: 20px;

        .van-image {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .info {
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin-bottom: 4px;

        .nickname {
          font-size: 13px;
          line-height: 22px;
          color: #ffffff;
          flex: 1;
        }

        .stage {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 10px;

          span {
            height: 4px;
            width: 4px;
            border-radius: 100%;
            background: #64FF8F;
            margin-right: 4px;
          }
        }
      }

      .tag {
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin-bottom: 10px;
        height: 20px;

        >div {
          border-radius: 3px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .age {
          background: rgba(253, 32, 132, 0.3);
          color: #FD2084;
          margin-right: 4px;
          width: 34px;
        }

        .height {
          background: rgba(203, 172, 65, 0.3);
          color: #CBAC41;
          margin-right: 4px;
          width: 43px;
        }

        .job {
          background: rgba(171, 119, 248, 0.3);
          color: #AB77F8;
          padding: 0 4px;
        }
      }

      .position {
        display: flex;
        align-items: center;
        padding: 0 10px 3px;

        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        div {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}

/deep/.van-list__loading {
  width: 100%;
}</style>