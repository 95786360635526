<template>
  <van-popup
      :value="value"
      :overlayStyle="{ background: 'rgba(20, 21, 30, 0.6)' }"
      :style="{ width: '100%', maxHeight: '80%', minHeight:'35%', background: '#1E232D', padding: '0 10px' }"
      :transition-appear=true
      position="bottom"
      duration="0.5"
      @input="val => this.$emit('input', val)"
      @open="init"
      @close='closePop'
      round
  >
    <div class="title">
      选择位置
    </div>
    <div class="pop-body">
      <div class="curr-line">
        <div>
          <span>当前: </span>
          <p @click="changeCity" >{{ $gbUtils.getCityNameByCode(areaCode) }} <img src="@/assets/img/icon/arrow-down.svg" alt=""></p>
        </div>
      </div>
      <!-- 标签组 -->
      <div class="label-groups">
        <div class="item"
             v-for="(item,index) in areaItemList" :key="index"
             :class="{'selected': labelItems[item.code]}"
             @click="select(item.code)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

  </van-popup>

</template>

<script>
import {mapActions, mapState} from "vuex";
import $gbUtils from "../../utils/gbUtils";
import bus from "@/utils/bus";

export default {
  name: '',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      labelItems: {}
    }
  },
  computed: {
    ...mapState(['areaCode']),
    $gbUtils() {
      return $gbUtils
    },
    areaItemList() {
      return this.getAreaListByCityCode(this.areaCode)
    }
  },
  methods: {
    ...mapActions(['changeAreaCode']),
    init() {
      console.log('this.areaCode: ' + this.areaCode)
      this.getAreaListByCityCode()
      this.labelItems[this.areaCode] = true
      this.$forceUpdate()
    },
    getAreaListByCityCode() {
      if (!this.areaCode) this.areaCode = '110101'
      const cityCode = this.areaCode.substr(0, 4)
      let areaList = []
      areaList.push({
        code: cityCode + '00',
        name: this.$gbUtils.getCityNameByCode(this.areaCode)
      })
      return areaList.concat( this.$gbUtils.getAreaListByCityCode(cityCode) )
    },
    closePop() {
      this.$emit('input', false)
    },
    handleConfirm() {
      this.$emit('getSelectedList', this.getSelected())
      this.closePop()
    },
    select(code) {
      for (let key in this.labelItems) {
        this.labelItems[key] = false
      }
      this.labelItems[code] = true
      this.$forceUpdate()
      this.changeAreaCode(code)
      this.closePop()
      bus.$emit('reloadHomeData')
    },
    getSelected() {
      let finalList = []
      for(let key in this.labelItems) {
        if(this.labelItems[key]) {
          finalList.push(key)
        }
      }
      return finalList
    },
    changeCity() {
      const path = `/personalEdit/editAddress?addressKey=loadResourceForCity&areaLevel=1&multiple=false&maxCount=1&title=位置`
      this.$gbUtils.handleToPage(path)
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>

.title {
  font-size: 20px;
  display: flex;
  padding: 0 10px;
  margin: 20px 0;
  font-weight: 600;
  .title-left {
    font-weight: bold;
    color: #fff;
  }
  .title-right {
    color: #FD2084;
  }
}

.selected { // 选中时，背景色（暂定）
  // background: linear-gradient(151deg, #F00CD9 0%, #A105DE 67%);
  background: rgba(253, 32, 132, 0.3);
  color:#FD2084;
  border: none !important;
}


.pop-body {
  padding-bottom: 50px;
  .curr-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 10px;
    font-size: 14px;
    >div {
      display: flex;
      align-items: center;
      >p {
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }
  .label-box {
    border: 1px solid gray;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;

    .label-item {
      width: 26%;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      border-radius: 20px;
      font-size: 16px;
    }
  }

  .label-groups {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 26%;
      height: 25px;
      border: 0.5px solid #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px;
      border-radius: 20px;
      font-size: 12px;
      opacity: 0.6;
    }
  }

  .btn-groups {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    .btn {
      width: 45%;
      height: 35px;
    }
  }

}
.van-popup--round {
  border-radius: 5px 5px 0 0;
}

</style>